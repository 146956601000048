<template> 
  <div class="content">  
  
    <div class="text-left col-lg-8">

      <table>

        <tr>
          <th>Descrição &ensp; • &ensp;</th>
          <th>Check-in &ensp; • &ensp;</th>
          <th>Check-out &ensp; • &ensp;</th>
          <th>Restam &ensp; • &ensp;</th>
          <th>Total</th>
        </tr>

        <tr v-for="[index, item] in sumarioReserva.entries()" v-bind:key="item.descricao">
          <td>{{item.descricao}}</td>
          
          <td style="text-align: center; background-color: rgb(35, 233, 62);" v-if="index == 1"><b>{{item.checkIn}}</b></td>
          <td style="text-align: center;" v-else><b>{{item.checkIn}}</b></td>

          <td style="text-align: center;"><b>{{item.checkOut}}</b></td>
          <td style="text-align: center;"><b>{{item.restam}}</b></td>
          <td style="text-align: center;"><b>{{item.total}}</b></td>
        </tr>

      </table> 

    </div>

  </div> 
</template>
 
<script>  
export default {
  name: 'ModalSumarioReservasHospedes', 
  props:{ 
    sumarioReserva: null
    // sumarioHospedes: null,
    // sumarioCedeirantes: null,
    // sumarioPets: null
  },

  data() {
    return {  

    }
  }

};

</script>
 
 <style scoped>

  .content {
    align-content: center;
  }


  span {
    padding: 30px;
    font-size: 50px;
    font-weight: 600;
  }

</style>