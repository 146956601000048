<template>
    
    <div class="loading">
        
        <!-- <b-icon icon="square" animation="throb" font-scale="4"></b-icon> -->
        <b-iconstack font-scale="4" variant="white" animation="spin">
            <b-icon stacked icon="circle-fill" variant="dark"></b-icon>
            <b-icon stacked icon="arrow-up-short" scale="0.5" shift-v="3" shift-h="-3" rotate="30"></b-icon>
            <b-icon stacked icon="arrow-up-short" scale="0.5" shift-v="3" shift-h="3" rotate="120"></b-icon>
            <b-icon stacked icon="arrow-up-short" scale="0.5" shift-v="-3" shift-h="3" rotate="210"></b-icon>
            <b-icon stacked icon="arrow-up-short" scale="0.5" shift-v="-3" shift-h="-3" rotate="300"></b-icon>
        </b-iconstack>
        <span id="span-loading">Carregando...</span>

    </div>

</template>

<script>

export default {
    name: 'Loading'
}
</script>

<style>

    .loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 75vh;
    }

    #span-loading {
        font-size: 20px;
        margin-top: 2px;
    }

</style>