<template>
  <div id="page">

    <span class="valor-devolucao">{{valorTotal}}</span>
    
    <div>
      <b-table
        striped
        hover
        class="text-left my-table"
        ref="selectableTable"
        :items="cartoes"
        :fields="fieldsCartoes"
        :busy="load"
        small
        outlined
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong></strong>
          </div>
        </template>

        <template #cell(Selecione)="sel">
          <b-button size="sm" variant="info" @click="selecionarReserva(sel.item)" class="mr-0">
            Selecione
          </b-button>
        </template>

      </b-table>
    </div>


    <b-button variant="success" class="float-right botao-avulso" @click="fecharCartoes">
    Confirmar o fechamento
    </b-button>
    


  </div>
</template>




<script>
// import dayjs from 'dayjs'
import api from "../../../services/api";
// import { acessoRestrito } from "@/global";

export default {
  name: "ModalFecharCartao",
  props: {
    idReserva: null,
    codsCartoes: []
  },

  data() {
    return {


      valorTotal: '',


      cartoes: [],
      fieldsCartoes: [
        { key: "numeroCartao", label: "Nº Cartão" },
        { key: "nome", label: "Nome" },
        { key: "saldo", label: "Saldo", formatter: value => {
          return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }},
      ],

      load: false,

    };
  },



  created() {
    // console.log('aaaaaaaaaaaa',this.codsCartoes)
    this.getCartoesParaFechar()
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    getCartoesParaFechar() {
      this.load = true;

      api.get(`bar/cartoes-fechar/${this.codsCartoes}`)
      .then((res) => {
        this.load = false
        this.cartoes = res.data

        let valorTotal = this.cartoes.reduce((total, item) => total + Number(item.saldo), 0)
        valorTotal = valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        this.valorTotal = `Valor de devolução: ${valorTotal}`
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },   

    fecharCartoes() {

      const valorDevolucao = this.valorTotal.split(': ')[1]

      api.post(`bar/cartoes-fechar`, { idReserva: this.idReserva, codsCartoes: this.codsCartoes, valorDevolucao})
      .then((res) => {
        this.msgNotification(res.data, 'success', 5000)
        this.$emit('EMITfecharCartao')
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },  
    
  
  },

};

</script>

<style scoped>

.my-table{
  margin-top: 40px;
}

.valor-devolucao{
  font-size: 27px;
  font-weight: bold;
}

.botao-avulso{
  margin-top: 20px;
}

.botao-confirmar {
    width: 45%; 
    margin-left: 10px;
}

</style>