import dayjs from 'dayjs'
import api from "../../../services/api";
import ModalReservas from './ModalReservas'
import ModalFecharCartao from './ModalFecharCartao'
import { acessoRestrito } from "@/global";

export default {
  name: "CreditosCartoes",
  components: { 
    ModalReservas,
    ModalFecharCartao
   },

  data() {
    return {

      filtro: '',
      codBarras: null,
      teclasDigitadas: '',

      cartao: {
        codBarras: null,
        idReserva: null,
        quarto: null,
        nome: null,
        dataIn: null,
        dataOut: null,
        saldo: null,
        status: null,
        userCreate: null,
        dataCreate: null,
        creditos: [],
        consumo: []
      },

      valorCredito: null,
      credito: {},

      


      fieldsCreditos: [
        { key: "userCreate", label: "Usuário" },
        { key: "dataCreate", label: "Data", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY HH:mm') : null
        }},
        { key: "valor", label: "Crédito", formatter: value => {
          return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }},
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],


      fieldsConsumo: [
        { key: "produto", label: "Produto" },
        { key: "dataCreate", label: "Data compra", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY HH:mm') : null
        }},
        { key: "valor", label: "Valor", formatter: value => {
          return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }},
      ],

      cartoesAbertos: [],
      fieldsCartoesAbertos: [
        { key: "numeroCartao", label: "Nº Cartão" },
        { key: "nome", label: "Nome" },
        { key: "codReserva", label: "Reserva" },
        { key: "quarto", label: "Quarto" },
        { key: "dataIn", label: "Entrada" },
        { key: "dataOut", label: "Saída" },
        { key: "saldo", label: "Saldo" },
        { key: "Selecione", label: "" },
      ],



      items: [], 

      load: false,
      loadCartoesAbertos: false,

    };
  },



  created() {
    // this.getCartao()
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },


    money: {
      decimal: '.',
      thousands: '.',
      prefix: 'R$ ',
      // suffix: ' #',
      precision: 2,
    },


    teclaEnter(event) {
      // console.log(event)
      if (event.keyCode == 13)
      this.addCredito()
    },



    getCartao() {
      acessoRestrito(100)

      this.load = true;

      api.get(`bar/cartao/${this.codBarras}`)
      .then((res) => {
        this.load = false
        this.cartao = res.data
      })
      .catch((err) => {
        this.load = false
        if (err.response.status == 400 || err.response.status == 406)  {

          this.msgNotification(err.response.data, 'info', 5000)

          if (err.response.status == 406) {

            this.resetCartao()
            this.$refs["modal-abrir-cartao"].show()          
          }

        }
        else
          this.msgNotification(err.response.data, 'error', 5000)
      });

    },   

    addCredito() {

      acessoRestrito(102)

      if (this.valorCredito.length > 11) {
        this.valorCredito = null
        return
      }


      if (!this.cartao.codBarras)
      return
      
      if (!this.valorCredito || this.valorCredito < 1 || this.valorCredito == 'R$ 0,00') {
        this.msgNotification('Informe um valor válido.', 'warning', 5000)
        return
      }


      this.$refs.focusSaldo.focus()


      const dados = {
        idReserva: this.cartao.idReserva,
        idCartaoAberto: this.cartao.id,
        valor: this.valorCredito,
      }

      api.post(`bar/cartao-credito`, dados)
      .then(() => {
        this.valorCredito = null
        this.msgNotification('Crédito adicionado.', 'success', 5000)
        this.getCartao()
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });
      
    },


    

    showModalApagar(item) {
      acessoRestrito(103)

      this.$refs["modal-del"].show();
      this.credito = item;
    },

    apagar() {
      // acessoRestrito(1003)
      this.$refs["modal-del"].hide();
      const idReserva = this.cartao.idReserva ? this.cartao.idReserva : '0'

      api.delete(`bar/cartao-credito/${idReserva}/${this.cartao.id}/${this.credito.id}/${this.credito.valor}`)
      .then(() => {
        this.msgNotification('Crédito removido.', 'success', 5000)
        this.getCartao()
      })
      .catch((err) => this.msgNotification(err.response.data, 'warning', 5000));
        
    },


    showModalCartoesAbertos() {
      acessoRestrito(101)

      this.$refs["modal-cartoes-abertos"].show();
      this.getCartoesAbertos()
    },

    getCartoesAbertos() {
      this.loadCartoesAbertos = true;
      this.valorCredito = null

      api.get(`bar/cartao-abertos`)
      .then((res) => {
        this.loadCartoesAbertos = false
        this.cartoesAbertos = res.data
      })
      .catch((err) => {
        this.loadCartoesAbertos = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },  

    selecionarCartao(cartao) {
      acessoRestrito(104)
      this.codBarras = cartao.codBarras
      this.cartao = {...cartao}
      this.$refs["modal-cartoes-abertos"].hide();
    },


    EMITabrirCartao() {
      this.$refs["modal-reservas"].hide();
      this.$refs["modal-abrir-cartao"].hide()
      this.getCartao()
    },
    
    fecharCartao() {
      acessoRestrito(105)
      this.$refs["modal-fechar-cartao"].show()
    },

    EMITfecharCartao() {
      this.$refs["modal-fechar-cartao"].hide()
      this.resetCartao()
    },




     resetCartao() {

      const cartao = this.cartao
      
      for (var proper in cartao){
        
        if (proper == 'creditos' || proper == 'consumo') {
          cartao[proper] = []
        }
        else
        cartao[proper] = null
      }
      
    },





    iniciaTimerTeclas() {
            
      const timer = setInterval(() => {

        this.teclasDigitadas = '' 

        clearInterval(timer)

      }, 2000)

    },
    // iniciaTimerTeclas() {
            
    //   const timer = setInterval(() => {

    //     this.teclasDigitadas = '' 

    //     clearInterval(timer)

    //   }, 1000)

    // },
  
  },


  mounted() {

    // window.addEventListener("keypress", e => {
    //   // console.log(String.fromCharCode(e.keyCode));
    //   // console.log(e);
    //   const teclaPres = String.fromCharCode(e.keyCode)

    //   if(teclaPres.match(/[0-9]+/)) {
    //     this.teclasDigitadas += teclaPres
    //     this.iniciaTimerTeclas()
    //   }

      
    //   if (e.keyCode == 13 && this.teclasDigitadas.length == 12) {
    //     this.$refs.focusSaldo.focus()
    //     this.codBarras = this.teclasDigitadas
    //     this.getCartao()
    //     this.teclasDigitadas = ''
    //   }
      
    //   // console.log('this.teclasDigitadas',this.teclasDigitadas);
    // });


    window.addEventListener("keypress", e => {
      // console.log(String.fromCharCode(e.keyCode));
      // console.log(e);
      const teclaPres = String.fromCharCode(e.keyCode)

      this.teclasDigitadas += teclaPres
      this.iniciaTimerTeclas()

      
      if (e.keyCode == 13 && this.teclasDigitadas.length >= 12) {
        this.$refs.focusSaldo.focus()
        this.codBarras = this.teclasDigitadas
        this.getCartao()
        this.teclasDigitadas = ''
      }
      
      // console.log('this.teclasDigitadas',this.teclasDigitadas);
    });

  },

  computed: {

    filtrar() {
  
      const busca = this.filtro.toString().toLowerCase()
      // alert(busca)

      if (busca.trim() == '') return this.cartoesAbertos
          
      let listaFiltrada = []
      this.cartoesAbertos.forEach((item) => {
        if(item.nome.toLowerCase().match(busca)) listaFiltrada.push(item) 
        if(item.quarto && item.quarto.toString().match(busca)) listaFiltrada.push(item) 
      })

      return [...new Set(listaFiltrada)]

    },

  },

  
};