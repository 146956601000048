<template>
  <div id="page-empresas">
    <b-list-group>
      <div>
        <h2>Empresas Convêniadas</h2>

          <b-row align-h="between">
              
              <button class="button-cadastrar" @click="showModalAdd">Cadastrar</button>
              <!-- <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button> -->

              <div class="form-group col-lg-4">
                  <label><span class="text-danger"></span> Buscar</label>
                  <input class="form-control"  v-model="filtro" />
              </div>

          </b-row>
      </div>


      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="items"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>

          <template #cell(Editar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../../assets/edit1.png"
              alt="editar"
              height="23px"
              @click="Editar(data.item)"
            />
          </template>
          <template #cell(Apagar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>


        </b-table>
      </div>
    </b-list-group>




    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Empresa convêniada" size="xl">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> Nome da Empresa</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.nome"
              onkeyup="return lowerCase(this)"
              maxlength="100"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span> CNPJ</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.cnpj"
              v-mask="'##.###.###/####-##'"
              maxlength="18"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Data do contrato</label>
            <b-form-input
              type="date"
              id="datepiker"
              v-model="form.dataContrato"
              locale="pt"
            ></b-form-input>
          </div>


          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span>Contato</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.contato"
              maxlength="50"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span>E-mail</label>
            <b-form-input
              class="text-lowercase"
              v-model="form.email"
              maxlength="100"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span>Telefone</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.fone"
              v-mask="'(##) ####-####'" 
              placeholder="(00) 0000-0000"
              maxlength="14"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span>Celular</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.cel"
              v-mask="'(##) #####-####'" 
              placeholder="(00) 00000-0000"
              maxlength="15"
            ></b-form-input>
          </div>


          <div class="form-group col-lg-2">
            <label><span class="text-danger">*</span>CEP</label>
            <b-form-input
              v-model="form.cep"
              v-mask="'#####-###'" 
              placeholder="00000-000"
              maxlength="9"
              @keyup="buscarCep()"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-4">
            <label><span class="text-danger">*</span>Endereço</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.endereco"
              maxlength="100"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span>Bairro</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.bairro"
              maxlength="40"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span>Cidade</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.cidade"
              maxlength="40"
            ></b-form-input>
          </div>



          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span> Valor convêniado</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.valorDiaria"
              @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger">*</span> Valor parênte</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.valorParente"
              @keyup="formatMoeda($event)" 
            ></b-form-input>
          </div>

          

          <div class="form-group col-lg-2">
            <label><span class="text-danger">*</span> Valor convidado</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.valorConvidado"
              @keyup="formatMoeda($event)" 
            ></b-form-input>
          </div>


          <div class="form-group col-lg-2">
            <label><span class="text-danger">*</span> Valor criança (6-12)</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.valorCrianca6_12"
              @keyup="formatMoeda($event)" 
            ></b-form-input>
          </div>

          <div class="form-group col-lg-2">
            <label><span class="text-danger">*</span> Valor Pet</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.valorPet"
              @keyup="formatMoeda($event)" 
            ></b-form-input>
          </div>


          

        </div>

        <b-container class="bv-example-row">
          <b-row align-h="start">
            <b-button class="mt-3 botao-confirmar2" variant="success" @click="save">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar2" variant="danger" @click="$refs['modal-add'].hide()">Cancelar</b-button>
          </b-row>

          <!-- <b-row align-h="around">
            <b-button class="mt-3 botao-confirmar" variant="success" @click="validaHospede">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-add-hospede'].hide()">Cancelar</b-button>
          </b-row> -->
        </b-container>

      </b-modal>
    </div>
    <!-- MODAL CADASTRO FIM -->




    <!-- MODAL DELETAR -->
    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Deseja remover a empresa?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-del'].hide()"
          >Não</b-button
        >
      </b-modal>
    </div>
    <!-- MODAL DELETAR FIM-->



  </div>
</template>

<script>
import dayjs from "dayjs"
import axios from "axios";
import api from "../../services/api";
import { acessoRestrito } from "@/global";

export default {
  name: "EmpresasConvenio",

  data() {
    return {

      filtro: '',

      form: {
        nome: null,
        cnpj: null,
        contato: null,
        email: null,
        endereco: null,
        bairro: null,
        cidade: null,
        cep: null,
        fone: null, 
        cel: null,
        dataContrato: null,
        valorDiaria: null
      },

      fields: [
        { key: "nome", label: "Empresa" },
        { key: "cnpj", label: "Cnpj" },
        { key: "dataContrato", label: "Data contrato", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY') : null
        }},
        { key: 'valorDiaria', label: 'Valor diária', formatter: value => {
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }, thClass: 'text-right', tdClass: 'text-right'},
        { key: "Editar", label: "Editar", thClass: 'text-right', tdClass: 'text-right' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 

      load: false,

    };
  },



  created() {
    this.getEmpresasConvenios();
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },
    

    async buscarCep() {
      // console.log("cep", this.form.cep, this.form.cep.length, evento);

      if (this.form.cep.length == 9) {

        axios.get(`https://viacep.com.br/ws/${this.form.cep}/json`)
        .then(res => {
          // console.log(res.data);

          const dadosCep = res.data;
          this.form.endereco = dadosCep.logradouro;
          this.form.bairro = dadosCep.bairro;
          this.form.cidade = dadosCep.localidade ? dadosCep.localidade+' - '+dadosCep.uf : null;

        })
        .catch((err) => this.msg("Erro: cep não encontrado.", err, "danger"));
      }
      else {
        this.form.endereco = ''
        this.form.bairro = ''
        this.form.cidade = ''
      }

    },


    formatMoeda(event) {
      
        // console.log(event.target.value)
        let input = event.target

        var valor = input.value.replace(/,/, '')

        if (valor.length > 2)
            input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
        else   
        if (valor.length > 0 && valor.length < 3)
            input.value = valor
        else   
            input.value = ''

    },



    getEmpresasConvenios() {
      this.load = true;

      api.get(`empresas-convenio`)
      .then((res) => {
        this.load = false
        this.items = res.data
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },   


    showModalAdd() {
      acessoRestrito(11)
      this.resetForm()
      this.$refs["modal-add"].show()
    },

    save() {

      api.post("empresas-convenio", this.form)
      .then(res => {

        this.$refs["modal-add"].hide()
        this.msgNotification(res.data, 'success', 5000)
        this.getEmpresasConvenios()
        this.resetForm()

      })
      .catch((err) => {
        // this.load = false;
        this.msgNotification(err.response.data, 'warning', 5000)
      });

    },


    Editar(item) {
      acessoRestrito(12)
      this.form = {...item}
      this.$refs["modal-add"].show()
    },
    

    showModalApagar(item) {
      this.$refs["modal-del"].show();
      this.form = item;
    },

    apagar() {
      acessoRestrito(13)
      this.$refs["modal-del"].hide();

      api.delete(`empresas-convenio/${this.form.id}`)
      .then(() => {
        this.msgNotification('Empresa removida.', 'success', 5000)
        this.getEmpresasConvenios()
      })
      .catch((err) => this.msgNotification(err.response.data, 'error', 5000));
        
    },




     resetForm() {

      const form = this.form
      
      // this.filtroNomeSocio = null
      // this.socioSituacaoNoCadastro = null
      
      for (var proper in form){
        
        form[proper] = null
      }
      
    },



  },

  computed: {

      filtrar() {

          // console.log('opaa')
          
          // if (this.filtro.trim() == '') return this.items
              
          // let listaFiltrada = []
          // this.items.forEach((item) => {
          //     if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
          //     if(item.saldo && item.saldo.toString().match(this.filtro)) listaFiltrada.push(item) 
          // })

          // return listaFiltrada
          return this.items
      },
  },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */



.button-cadastrar {
  margin-top: 30px;
  margin-left: 15px;
  height: 40px;
  width: 190px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.botao-confirmar {
  width: 45%; 
  /* width: 170px;  */
  margin-left: 10px;
}

.botao-confirmar2 {
  width: 170px; 
  margin-left: 10px;
}

#page-empresas h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

/* .btnRight {
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  font-weight: bold;
} */

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>