import Vue from 'vue'
import VueRouter from 'vue-router'

import ConsultarSaldo from '../pagesPublic/ConsultarSaldo'

import Home from '../pages/Home'
import Dashboard from '../pages/Dashboard'
import DashboardContas from '../pages/DashboardContas'
import Avisos from '../pages/Avisos'

import GerenciarUsuarios from '../pages/admin/GerenciarUsuarios'
import Login from '../pages/auth/Login'
import AlterarSenha from '../pages/auth/AlterarSenha'
import Ajuda from '../pages/tutoriais/Ajuda'

import EmpresasConvenio from '../pages/empresasConvenio/EmpresasConvenio'
import Periodos from '../pages/Periodos'
import Ocorrencias from '../pages/Ocorrencias'
import Quartos from '../pages/quartos/Quartos'
import Historicos from '../pages/Historicos'

import Bar from '../pages/bar/creditosCartoes/CreditosCartoes'
import Produtos from '../pages/bar/produtos/Produtos'
import RelatoriosBar from '../pages/bar/relatorios/RelatoriosBar'

import Reservas from '../pages/reservas/Reservas'
import ConsultarSocio from '../pages/ConsultarSocio'
import ConsultarDisponibilidade from '../pages/ConsultarDisponibilidade'


Vue.use(VueRouter)


const routes = [

    // Rotas publicas (sem autênticação)
    //------------------------------------------------------------------------------------
    { name: 'consultarSaldo', path: '/publico/consultar-saldo', component: ConsultarSaldo },
    //------------------------------------------------------------------------------------


    { name: 'home', path: '/home', component: Home },
    { name: 'dashboard', path: '/dashboard', component: Dashboard },
    { name: 'dashboardContas', path: '/dashboard-contas', component: DashboardContas  },
    { name: 'avisos', path: '/avisos', component: Avisos },

    { name: 'gerenciarUsuarios', path: '/gerenciar-usuarios', component: GerenciarUsuarios },
    { name: 'auth', path: '/login', component: Login },
    { name: 'alterarSenha', path: '/alterar-senha', component: AlterarSenha },

    { name: 'ajuda', path: '/ajuda', component: Ajuda },

    
    { name: 'EmpresasConvenio', path: '/empresas-convenio', component: EmpresasConvenio },
    { name: 'Ocorrencias', path: '/ocorrencias', component: Ocorrencias },
    { name: 'Periodos', path: '/periodos', component: Periodos },
    { name: 'Quartos', path: '/quartos', component: Quartos, props: true },
    { name: 'Historicos', path: '/historicos', component: Historicos },
    
    { name: 'Bar', path: '/bar', component: Bar, props: true },
    { name: 'Produtos', path: '/produtos', component: Produtos, props: true },
    { name: 'RelatoriosBar', path: '/bar/relatorios', component: RelatoriosBar, props: true },

    { name: 'Reservas', path: '/reservas', component: Reservas },
    { name: 'ConsultarSocio', path: '/consultar-socio', component: ConsultarSocio },
    { name: 'ConsultarDisponibilidade', path: '/consultar-disponibilidade', component: ConsultarDisponibilidade },
]





const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})


// router.beforeEach((to, from, next) => {

    // const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    // const auth = store.state.auth.isMenuVisible 

    // console.log(requiresAuth, auth)

    // if(requiresAuth && !auth) {
    //     next('/login')
    // } else {
    //     next()
    // }





    // const pathAnterior = localStorage.getItem('path')

    // // console.log("pathAnterior:",pathAnterior,"  estou aqui:",from.path,'   indo para:',to.path)

    // if (pathAnterior == '/neutro') {
    //     localStorage.setItem('path', '/neutro')
    //     next()  
    // } else
    // if (pathAnterior == to.path) {
    //     // console.log('negado')
    //     // console.log('---------------------------------')
    //     next(false)
    // } else {
    //     // console.log('Permitido')
    //     // console.log('---------------------------------')
    //     localStorage.setItem('path', from.path)
    //     next()
    // }
// })





// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
//     console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')

//     console.log('dddddddddd', mapState(['isAuth']))
    
//     const isAuth =  

//     console.log('vbbbbbbbbbbbbbbbbbbbbbb', isAuth)

//     if (requiresAuth && !isAuth) {
//         next({ name:'auth' })   
//         // next()
//     }else{
//         next()
//     }
// })

export default router