<template>
  <div id="page">
    <b-list-group>
      <div>
        <h2>Produtos</h2>

          <b-row align-h="between">
              
              <button class="button-cadastrar" @click="showModalAdd">Cadastrar</button>
              <!-- <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button> -->

              <div class="form-group col-lg-4">
                  <label><span class="text-danger"></span> Buscar</label>
                  <input class="form-control"  v-model="filtro" />
              </div>

          </b-row>
      </div>


      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="filtrar"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>


          <template #cell(Estoque)="data">
            <b-icon class="cursor" icon="box-seam" variant="info" style="height: 18px; width: 25px;" @click="showModalEstoque(data.item)"></b-icon>
          </template>

          <template #cell(Editar)="data">
            <img
              src="../../../assets/edit1.png"
              alt="editar"
              height="23px"
              @click="Editar(data.item)"
            />
          </template>

          <template #cell(Apagar)="data">
            <img
              src="../../../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>


        </b-table>
      </div>
    </b-list-group>




    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Produto" size="lg">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> Nome do produto</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.nome"
              onkeyup="return lowerCase(this)"
              maxlength="100"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> Código de barras</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.codBarras"
              maxlength="18"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span>Categoria</label>
            <b-form-select 
              v-model="form.categoria" 
              :options="categorias" 
              @change="getReservas()">
          </b-form-select>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> Valor</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.valor"
              @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

        </div>

        <b-container class="bv-example-row">
          <b-row align-h="start">
            <b-button class="mt-3 botao-confirmar2" variant="success" @click="save">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar2" variant="danger" @click="$refs['modal-add'].hide()">Cancelar</b-button>
          </b-row>

          <!-- <b-row align-h="around">
            <b-button class="mt-3 botao-confirmar" variant="success" @click="validaHospede">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-add-hospede'].hide()">Cancelar</b-button>
          </b-row> -->
        </b-container>

      </b-modal>
    </div>
    <!-- MODAL CADASTRO FIM -->




    <!-- MODAL DELETAR -->
    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Deseja remover o produto?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-del'].hide()"
          >Não</b-button
        >
      </b-modal>
    </div>
    <!-- MODAL DELETAR FIM-->



    <!-- MODAL ESTOQUE -->
    <div>
      <b-modal ref="modal-estoque" hide-footer title="Estoque" size="lg">
        <ModalEstoque :produto="produtoParaEstoque"
          @EMIT_Produtos_atualizaProduto="EMIT_Produtos_atualizaProduto($event)">
        </ModalEstoque>
      </b-modal>
    </div>
    <!-- MODAL ESTOQUE FIM -->



  </div>
</template>

<script>
import api from "../../../services/api";
import { acessoRestrito } from "@/global";
import ModalEstoque from './ModalEstoque.vue'

export default {
  name: "Produtos",
  components: { 
    ModalEstoque
   },

  data() {
    return {

      filtro: '',

      form: {
        nome: null,
        codBarras: null,
        categoria: null,
        valor: null
      },

      fields: [
        { key: "nome", label: "Produto", sortable: true },
        { key: "categoria", label: "Categoria", sortable: true },
        { key: "codBarras", label: "Cód. Barras", sortable: true },
        { key: 'valor', label: 'Valor', formatter: value => {
          return  value.toLocaleString('pt-br', {
            style: 'currency',
                      currency: 'BRL'
                  });
        }, thClass: 'text-right', tdClass: 'text-right', sortable: true },
        { key: "quantidade", label: "Quantidade", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "Estoque", label: "Estoque", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Editar", label: "Editar", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 

      categorias: [
        'BEBIDAS',
        'ALCOÓLICAS',
        'SALGADOS',
        'DOCES',
        'PORÇÕES',
        'DIVERSOS'
      ],

      load: false,

      produtoParaEstoque: {}

    };
  },



  created() {
    this.getProdutos();
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    formatMoeda(event) {
      
        // console.log(event.target.value)
        let input = event.target

        var valor = input.value.replace(/,/, '')

        if (valor.length > 2)
            input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
        else   
        if (valor.length > 0 && valor.length < 3)
            input.value = valor
        else   
            input.value = ''

    },



    getProdutos() {
      this.load = true;

      api.get(`produtos`)
      .then((res) => {
        this.load = false
        this.items = res.data
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },   


    showModalAdd() {
      acessoRestrito(36)
      this.resetForm()
      this.$refs["modal-add"].show()
    },

    save() {

      api.post("produto", this.form)
      .then(res => {

        this.$refs["modal-add"].hide()
        this.msgNotification(res.data, 'success', 5000)
        this.getProdutos()
        this.resetForm()

      })
      .catch((err) => {
        // this.load = false;
        this.msgNotification(err.response.data, 'warning', 5000)
      })
    },


    Editar(item) {
      acessoRestrito(37)
      this.form = {...item}
      this.$refs["modal-add"].show()
    },
    

    showModalApagar(item) {
      acessoRestrito(38)
      this.$refs["modal-del"].show();
      this.form = item;
    },

    apagar() {
      
      this.$refs["modal-del"].hide();

      api.delete(`produto/${this.form.id}`)
      .then(() => {
        this.msgNotification('Produto removido.', 'success', 5000)
        this.getProdutos()
      })
      .catch((err) => this.msgNotification(err.response.data, 'error', 5000));
        
    },

    resetForm() {

      const form = this.form
      
      for (var proper in form){
        
        form[proper] = null
      }
      
    },



    // ESTOQUE
    //------------------------------------------------------------------

    showModalEstoque(item) {
      acessoRestrito(39)
      this.produtoParaEstoque = {...item}
      this.$refs["modal-estoque"].show()
    },


    EMIT_Produtos_atualizaProduto(dados) {
      // console.log('dados',dados)

      this.items.forEach(produto => {

        if (produto.id == dados.id) {
          // console.log('produto',produto)
          produto.quantidade = dados.quantidade
          produto.controlarEstoque = dados.controlarEstoque
        }
      })
    }

    //------------------------------------------------------------------


  },

  computed: {

      filtrar() {

          // console.log('opaa')
          
          if (this.filtro.trim() == '') return this.items
              
          let listaFiltrada = []
          this.items.forEach((item) => {
              if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
              if(item.categoria.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
              if(item.codBarras.toString().match(this.filtro)) listaFiltrada.push(item) 
          })

          return listaFiltrada
          // return this.items
      },
  },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */



.button-cadastrar {
  margin-top: 30px;
  margin-left: 15px;
  height: 40px;
  width: 190px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.botao-confirmar {
  width: 45%; 
  /* width: 170px;  */
  margin-left: 10px;
}

.botao-confirmar2 {
  width: 170px; 
  margin-left: 10px;
}

#page h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

/* .btnRight {
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  font-weight: bold;
} */

.tabela {
  width: 80vw;
}

img, .cursor {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>