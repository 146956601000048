<template>
  <div>
  

    <b-button class="button-aplicar" variant="success" block @click="gerarPdf()">{{via}}</b-button>  


  </div>
</template>



<script>
import { acessoRestrito } from "@/global";
import dayjs from 'dayjs';
import extenso from 'extenso'
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";

export default {
  name: "BotaoRecibo",
  props: {
    reserva: {},
    via: null
  },


  data() {
    return {

      load: false,

      user: {},

      valorExtenso: null,
      valor: null,
      dataHoje: null,
      porcentagem: null,
      userName: null,


      meses: [
          { value: '01', text: 'Janeiro' },
          { value: '02', text: 'Fevereiro' },
          { value: '03', text: 'Março' },
          { value: '04', text: 'Abril' },
          { value: '05', text: 'Maio' },
          { value: '06', text: 'Junho' },
          { value: '07', text: 'Julho' },
          { value: '08', text: 'Agosto' },
          { value: '09', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
      ],

    }
  },


  created() {

    // const colonia = this.$store.state.colonia 


    // console.log(this.reserva)

    const authLocal = localStorage.getItem('user')
    this.user = JSON.parse(authLocal)
    this.userName = this.user.nome



    const dia = dayjs().format('DD')
    const mes = dayjs().format('MM')
    const mesTexto = this.meses.filter(item => item.value == mes)[0].text
    const ano = dayjs().format('YYYY')
    this.dataHoje = `${this.user.sede}, ${dia} de ${mesTexto} de ${ano}`
    

    var valorRecibo = null
    if (this.via == 'Recibo Sede') {
      valorRecibo = this.reserva.valorSinal 
    } 
    else {
      valorRecibo = this.reserva.valorRestante ? this.reserva.valorRestante : this.reserva.valorRestanteInicial 
    }

    // console.log('valorRecibo',valorRecibo)

    this.valor = valorRecibo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    
    this.valorExtenso = extenso(parseFloat(valorRecibo.toString().split('.').join(',')), { mode: 'currency', currency: { type: 'BRL' } })

    this.porcentagem = Math.trunc((valorRecibo / this.reserva.valorTotal) * 100)
  },
  

  methods: {



    getImgUrl(nomeFile) {
        var images = require.context('../../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },


    async gerarPdf() {

      this.via == 'Recibo Sede' ? acessoRestrito(253) : acessoRestrito(254)


      
      var doc = jsPDF('p', 'mm', [210, 297]);
      // var doc = jsPDF('l', 'mm', [210, 297]);

  

      // Topo
      //------------------------------------------------------------------
      var img = new Image()
      img.src = this.getImgUrl('logoSind') 
      doc.addImage(img, 'png', 9, 8, 27, 27) 

      const width = doc.internal.pageSize.getWidth()
      // const height = doc.internal.pageSize.getHeight()

      doc.setFontSize(17).setFont(undefined, 'bold'); 
      doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
      doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text('Sede Central - Osasco - SP', width/2, 28, { align: 'center' })
      
      doc.setFontSize(8).setFont(undefined, 'bold'); 
      doc.text('Rua Erasmo Braga, 307, 3º andar, - Presidente Altino - Osasco - SP - CEP:06213-000 - Telefone: (11) 3682-9303', width/2, 34, { align: 'center' })

      doc.setDrawColor(0, 0, 0);
      doc.line(0, 36, 500, 36);
      //------------------------------------------------------------------




      doc.setFontSize(15);          
      doc.text('RECIBO', width/2, 50, { align: 'center' })

      doc.setFontSize(10);          
      doc.text(`RESERVA ${this.reserva.cod}`, 170, 50)

      doc.text(this.valor, 194, 56, {maxWidth: 25, align: 'right'});

      doc.text(`RECEBI DE ${this.reserva.nome},  PORTADOR DO CPF: ${this.reserva.cpf}`, 14, 80)
      // doc.text('CPF: 329.207.778-82', 166, 80, {maxWidth: 40, align: 'justify'});

      doc.text(`A IMPORTANCIA DE (${this.valorExtenso.toUpperCase()})`, 14, 88)
      doc.text(`REFERÊNTE AO PAGAMENTO DE ${this.porcentagem}% do valor da reserva na Colônia Chico Amaro`, 14, 96)


      doc.text(this.dataHoje, 28, 140)
      doc.text('_______________________________________', 110, 140)
      doc.text(this.userName, 150, 146, {maxWidth: 60, align: 'center'});
      //------------------------------------------------------------------
                              


      if (this.via == 'Recibo Sede') {
        doc.save(`Recibo-sede nº ${this.reserva.cod}.pdf`)
      }
      else {
        doc.save(`Recibo-colônia nº ${this.reserva.cod}.pdf`)
      }
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>