import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './config/router'
import store from './stores/global'
// import './config/messages'
// import { showError, marcos } from '@/global'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VMask from "v-mask";
import money from 'v-money'




Vue.config.productionTip = false
// Vue.config.devtools = true
Vue.use(VMask);
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  // suffix: ' #',
  precision: 2,
  masked: false
})
Vue.use(VueToast);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

