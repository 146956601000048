<template> 
  <div>  
    

    <div class="text-center text-warning my-2" v-if="load">
        <b-spinner class="align-middle"></b-spinner>
        <strong></strong>
    </div>
    
    <div class="row col-lg-12" v-else>



      <div class="form-group col-lg-6">
        <label><span class="text-danger"></span> Placa</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="veiculo.placa"
          v-mask="'NNN-NNNN'"
          placeholder="000-0000"
          @keyup="filtrarPlaca"
        ></b-form-input>
      </div> 

      <div class="form-group col-lg-6">
        <label><span class="text-danger"></span> Modelo</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="veiculo.modelo"
        ></b-form-input>
      </div> 

      <!-- <b-card
        class="mt-3"
          header="Resultado">
        <pre class="m-0">{{ hospede }}</pre>
      </b-card> -->

        

      <b-container class="bv-example-row">
        <b-row align-h="start">
          <b-button class="mt-3 botao-confirmar2" variant="success" @click="adicionar()">Adicionar</b-button>
        </b-row>

        <!-- <b-row align-h="around">
          <b-button class="mt-3 botao-confirmar" variant="success" @click="validaHospede">Confirmar</b-button>
          <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-add-hospede'].hide()">Cancelar</b-button>
        </b-row> -->
      </b-container>



      <div class="tabela">
        <b-table
          id="my-table" 
          striped
          hover 
          ref="selectableTable"
          class="text-left" 
          :items="veiculos" 
          :fields="fieldsVeiculos" 
          :busy="load"
          value="true"
          unchecked-value="false"
          small
          outlined
          >              

          <template #table-busy>
              <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong></strong>
              </div>
          </template>
          

          <!-- <template #cell(Selecione)="row">
            <b-button size="sm" variant="info" @click="alert(row.item)" class="mr-0">
              Selecione
            </b-button>
          </template> -->


          <template #cell(Apagar)="data">
            <img
              class="cursor"
              src="../../../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="remover(data.item)"
            />
          </template>


        </b-table>
      </div>


    </div>




  </div> 
</template>
 
<script>
import { acessoRestrito } from "@/global";
import api from "@/services/api";
  
export default {
  name: 'ModalVeiculos', 
  props:{ 
      reserva: {}
  },

  data() {
    return {  

      load: false,


      veiculo: {
        modelo: null,
        placa: null,
      },

      veiculos: [],
      fieldsVeiculos: [
        {key: 'placa', label: 'Placa'},
        {key: 'modelo', label: 'Modelo'},
        {key: 'Apagar', label: 'Apagar'},
      ],
      


    }

  },
  created() { 

    // console.log(this.reserva)
    this.getVeiculos()

  },
  
  methods: {

    msgNotification(texto, tipo, tempo) {
        this.$toast.open({
            message: texto,
            type: tipo,
            position: 'top-right',
            duration: tempo
            // all of other options may go here
        })
    },


    filtrarPlaca() {

      if (this.veiculo.placa.length == 8) {
        console.log('busca placa', this.veiculo.placa)

        api.get(`reservas-veiculos-placa/${this.veiculo.placa}`)
        .then(res => {
          this.veiculo.modelo = res.data
        })
        .catch((err) => {
          this.msgNotification(err.response.data, 'warning', 5000)
        });

      } 
      else {
        this.veiculo.modelo = null
      }

    },


    getVeiculos() {

      this.load = true
      
      api.get(`reservas-veiculos/${this.reserva.id}`)
      .then(res => {
        this.load = false
        this.veiculos = res.data
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'warning', 5000)
      });
    },


    adicionar() {

      acessoRestrito(222)

      const veiculoExiste = this.veiculos.filter(item => item.placa == this.veiculo.placa)
      if (veiculoExiste.length > 0) {
        this.msgNotification('Placa cadastrada.', 'warning') 
        return
      }



      this.veiculo.idReserva = this.reserva.id
      this.load = true
      
      api.post(`reservas-veiculos`, this.veiculo)
      .then(() => {
        this.load = false
        this.msgNotification('veículo adicionado.', 'success')

        this.veiculo = {
          modelo: null,
          placa: null
        }

        // this.getVeiculos()
        this.$emit('EMITfecharVeiculo')
        
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'warning', 5000)
      });
    },


    remover(veiculo) {

      acessoRestrito(223)

      this.load = true
      
      api.delete(`reservas-veiculos/${veiculo.id}`)
      .then(() => {
        this.load = false
        this.msgNotification('Veículo removido.', 'success')

        this.getVeiculos()
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'warning', 5000)
      });
    }


  }

    
};

</script>
 
   
 <style scoped>


 .tabela {
    width: 80vw;
    margin-top: 0px;
  }



  .botao-confirmar2 {
    width: 170px; 
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .cursor { 
    cursor: pointer;
  }

</style>