import { render, staticRenderFns } from "./EmpresasConvenio.vue?vue&type=template&id=5489d418&scoped=true"
import script from "./EmpresasConvenio.vue?vue&type=script&lang=js"
export * from "./EmpresasConvenio.vue?vue&type=script&lang=js"
import style0 from "./EmpresasConvenio.vue?vue&type=style&index=0&id=5489d418&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5489d418",
  null
  
)

export default component.exports