import Vue from 'vue'
import Vuex from 'vuex'

import escala from './escala'
import auth from './auth'

Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: true,
    devtools: false,
    namespaced: true,
    state: {
        colonia: null,
        colonias: []
    },
    mutations: {

        setColonias(state, colonias) {
            state.colonias = colonias
        },

        setColonia(state, colonia) {
            state.colonia = colonia
        },
        
    },

    actions: {
        getAuth(state) {
            return state.isAuth
        }
    },
    modules: {
        auth: auth,
        escala: escala,
    }
})

export default store