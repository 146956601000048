<template>

  <div>

    <!-- <b-card
      class="mt-3"
        header="Resultado">
      <pre class="m-0">{{ comentario }}</pre>
      <pre class="m-0">{{ comentarios }}</pre>
    </b-card> -->

    <div class="wrapper">

      <div class="center-line"></div>
      
      <div :class="item.classCSS" v-for="item in comentariosComputed" v-bind:key="item.id">
        <section>
          <i class="icon">
            <b-icon class="b-icon" :icon="item.iconCSS" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> {{item.userCreate}}</span>
            <span>{{item.privacidade}}</span>
          </div>
          <p>{{item.comentario}}</p>
          <div class="bottom">

            <span>Reserva: {{item.cod}}</span>

            <i>{{item.dataCreateView}}</i>
          </div>
        </section>
      </div>

    </div>


  </div>

</template>



<script>
// import api from "../../services/api";
// import { msg } from "@/global";
export default {

  name: 'Comentarios',
  props: {
    comentarios: []
  },

  data() {
    return {

      optionsPrivacidade: ['Público','Privado'],
      selPrivacidade: 'Público',

      comentario: {},   
      textComentario: '',
      userName: null
  
    }
  },

  methods: {


    confirmar() {
      
      //

    }

  },


  computed: {

    comentariosComputed() {

      var filtra = this.comentarios.filter(item => !item.userDelete)

      // Aqui vem um if para checar permissão, caso não tenha, passa por esse filtro
      filtra = filtra.filter(item =>  item.privacidade == 'Público' || 
                                      item.privacidade == 'Privado' && item.userCreate == this.userName)

      // const filtra = this.reserva.comentarios
      
      return filtra
    },
  }


};
</script>



<style scoped>

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.wrapper{
  background: #ff7979;
  /* background: #8f23d6; */
  max-width: 1080px;
  margin: 20px auto;
  padding:  0 20px;
  position: relative;
}

.wrapper .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.wrapper .row{
  display: flex;
}

.wrapper .row-1{
  justify-content: flex-start;
}

.wrapper .row-2{
  justify-content: flex-end;
}

.wrapper .row section{
  margin-top: 5px;
  margin-bottom: 5px;
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.wrapper .row section::before{
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: 28px;
  /* z-index: -1; */
  transform: rotate(45deg);
}
.row-1 section::before{
  right: -7px;
}
.row-2 section::before{
  left: -7px;
}

.row section .icon{
  position: absolute;
  background: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ff7979;
  top: 13px;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.row-1 section .icon{
  right: -60px;
}
.row-2 section .icon{
  left: -60px;
}

.row section .details,
.row section .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row section .details .title{
  font-size: 22px;
  font-weight: 600;
}

.row section p{
  margin: 10px 0 17px 0;
}

.row section .bottom a{
  text-decoration: none;
  background: #ff7979;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 400;
}


.wrapper .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
}


.botao-confirmar {
  width: 200px; 
  height: 45px; 
  margin-top: 50px;
  margin-left: 11px;
  background: #ff7979;
  color: #fff;
  font-weight: bold;
}

.botao {
  width: 200px; 
  height: 45px; 
  margin-top: 50px;
  margin-left: 11px;
}



@media(max-width: 772px){

  .wrapper .center-line{
    left: 40px;
  }
  .wrapper .row{
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section{
    width: 100%;
  }
  .row-1 section::before{
    left: -7px;
  }
  .row-1 section .icon{
    left: -60px;
  }

}



</style>