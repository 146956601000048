<template>
  <div id="dashboard">


    <div v-if="mostrarGraficos">

      <div>
        <b-card-group deck>

          <b-card  text-variant="black" header="Frequência por períodos" class="text-center">
            <apexchart type="bar" height="480" :options="chartOptionsPeriodos" :series="seriesPeriodos"></apexchart>
          </b-card>

        </b-card-group>
      </div>

    </div>  


    <div v-else>

      <img src="../assets/logoSind2.png" alt="Dashboard" @click="toDashboard">

    </div>



  </div>
</template>



<script>
import api from "../services/api";
import VueApexCharts from 'vue-apexcharts'
export default {
  components: { apexchart: VueApexCharts },

  data() {
    return {


      visivel: false,
      mostrarGraficos: true,



      seriesPeriodos: [{
        name: 'Hóspedes',
        data: []
      }],

      chartOptionsPeriodos: null,      
        
  
    }
  },

  created() {

    // const colonias = this.$store.state.colonias  
    this.colonia = this.$store.state.colonia
    this.getInfoDashboad()

    // if (this.colonia == 'Chico Amaro' || this.colonia == 'Guarino Fernandes') {
    //   this.mostrarGraficos = true
    //   this.getInfoDashboad()
    // }
    // else {
    //   this.mostrarGraficos = false
    // }

  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    getInfoDashboad() {

      // const idSede = this.$store.state.sede.id
      // const idConta = this.$store.state.conta.id

      api.get(`dashboard/${this.colonia}`)
      .then((res) => {
        // console.log(res.data)

        this.seriesPeriodos = [{
          name: 'Quantidade hóspedes',
          data: res.data.dados
        }],
        this.chartOptionsPeriodos = res.data.grafico


        this.visivel = true
      })
      .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) })
    }


  }



};
</script>



<style scoped>
#dashboard {
  height: 91vh;
  width: 90vw;
  /* background-color: rgb(240, 240, 240); */
  padding: 35px;
}

.card-body {
  padding: 0;
}


img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



</style>