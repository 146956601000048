<template>
  <div id="page">
    
    <div>

      <div class="form-group col-lg-5 campo-filtro">
        <b-input-group>
            <input 
            class="form-control"  
            v-model="filtro" 
            placeholder="Pesquise por quarto ou nome"
            @keyup="filtrar()"
            />
            <b-input-group-append is-text class="text-monospace cursor">
              <b-icon icon="search" variant="success" class="cursor"></b-icon>
            </b-input-group-append>
          </b-input-group>
      </div>

      <b-table
        id="my-table"
        striped
        hover
        class="text-left"
        ref="selectableTable"
        :items="filtrar"
        :fields="fieldsReservas"
        :busy="load"
        small
        outlined
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong></strong>
          </div>
        </template>

        <template #cell(Selecione)="sel">
          <b-button size="sm" variant="info" @click="selecionarReserva(sel.item)" class="mr-0">
            Selecione
          </b-button>
        </template>

      </b-table>
    </div>


    <b-button variant="warning" class="float-left botao-avulso" @click="$refs['modal-nome-cartao-avulso'].show()">
    Abrir cartão avulso
    </b-button>
    





    <!-- MODAL NOME NO CARTÃO AVULSO -->
    <div>
      <b-modal ref="modal-nome-cartao-avulso" hide-footer title="Digite um nome para o cartão avulso">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-12">
            <label><span class="text-danger"></span>Nome</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="nomeCartaoAvulso"
            ></b-form-input>
          </div> 


          <b-container class="bv-example-row">
            <b-row align-h="around">
              <b-button class="mt-3 botao-confirmar" variant="success" @click="abrirCartaoAvulso">Abrir cartão</b-button>
              <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-nome-cartao-avulso'].hide()">Sair</b-button>
            </b-row>
          </b-container>

        </div>   
      </b-modal>
    </div>
    <!-- MODAL NOME NO CARTÃO AVULSO FIM-->


  </div>
</template>




<script>
import dayjs from 'dayjs'
import api from "../../../services/api";
// import { acessoRestrito } from "@/global";

export default {
  name: "ModalReservas",
  props: {
    codBarras: null
  },

  data() {
    return {

      nomeCartaoAvulso: null,
      filtro: '',

      reservas: [],
      fieldsReservas: [
        { key: "cod", label: "Reserva" },
        { key: "nome", label: "Nome" },
        { key: "quartos", label: "Quarto" },
        { key: "dataIn", label: "Entrada", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY') : null
        }},
        { key: "dataOut", label: "Saída", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY') : null
        }},
        { key: "Selecione", label: "" },
      ],

      load: false,

    };
  },



  created() {
    // console.log('aaaaaaaaaaaa',this.codBarras)
    this.getReservasComCheckin()
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },


    getReservasComCheckin() {
      this.load = true;

      api.get(`bar/reservas`)
      .then((res) => {
        this.load = false
        this.reservas = res.data
        // this.filtrar()
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },   


    selecionarReserva(reserva) {

      // console.log(reserva)
      
      const params = {
        codBarras: this.codBarras,
        idReserva: reserva.id,
        codReserva: reserva.cod,
        quarto: reserva.quartos,
        nome: reserva.nome,
        dataIn: reserva.dataIn,
        dataOut: reserva.dataOut
      }

      this.abrirCartao(params)
    },

    abrirCartaoAvulso() {

      if (!this.nomeCartaoAvulso) {
        this.msgNotification('Infome o nome do cartão avulso.', 'warning', 5000)
        return 
      }

      this.$refs['modal-nome-cartao-avulso'].hide()

      const params = {
        codBarras: this.codBarras,
        nome: this.nomeCartaoAvulso
      }

      this.abrirCartao(params)
    },


    abrirCartao(params) {
      // console.log(params)

      api.post(`bar/abrir-cartao`, params)
      .then(() => {
        this.msgNotification('Cartão aberto.', 'success', 5000)
        this.$emit('EMITabrirCartao')
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },
 
  
  },

  computed: {

    filtrar() {

      const busca = this.filtro.toString().toLowerCase()

        if (busca.trim() == '') return this.reservas
            
        let listaFiltrada = []
        this.reservas.forEach((item) => {
          if(item.nome.toLowerCase().match(busca)) listaFiltrada.push(item) 
          if(item.quartos.toString().match(busca)) listaFiltrada.push(item) 
        })

        return [...new Set(listaFiltrada)]
    },
  },


  
};

</script>

<style scoped>


.campo-filtro {
  margin-top: -10px;
}

.botao-avulso{
  margin-top: 20px;
}

.botao-confirmar {
    width: 45%; 
    margin-left: 10px;
}

</style>