<template>

  <div>

    <!-- <b-card
      class="mt-3"
        header="Resultado">
      <pre class="m-0">{{ comentario }}</pre>
      <pre class="m-0">{{ reserva.comentarios }}</pre>
    </b-card> -->
    
    <b-button class="botao-confirmar" variant="nome" @click="showAdicionar" v-if="modoEdicao">Adicionar comentário</b-button>
    <!-- <button class="button-cadastrar" @click="showModalReserva('insert')">Cadastrar</button> -->

    <div class="wrapper">


      <div class="center-line"></div>
      
      

      <div :class="item.classCSS" v-for="item in comentariosComputed" v-bind:key="item.id">
        <section>
          <i class="icon">
            <b-icon class="b-icon" :icon="item.iconCSS" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> {{item.userCreate}}</span>
            <span>{{item.privacidade}}</span>
          </div>
          <p>{{item.comentario}}</p>
          <div class="bottom">
            <b-icon 
              class="b-icon" 
              icon="trash-fill" 
              variant="none" 
              style="font-size: 19px; cursor: pointer; color: #ff7979"
              @click="showApagarComentario(item)"
              v-if="item.iconCSS != 'x-lg'"
              >
            </b-icon>

            <b-icon 
              class="b-icon" 
              icon="x-lg" 
              variant="none" 
              style="font-size: 19px; color: #ff7979"
              v-else
              >
            </b-icon>

            <i>{{item.dataCreateView}}</i>
          </div>
        </section>
      </div>

      <!-- <div class="row row-2">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="star-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Adriana Mogiano</span>
          </div>
          <p>Sócio chegará as 22hs da sexta.</p>
          <div class="bottom">
            <div class="button">
              <b-icon class="b-icon mr-2" icon="pencil-fill" variant="none" style="cursor: pointer; color: #ff7979"></b-icon>
              <b-icon class="b-icon" icon="trash-fill" variant="none" style="cursor: pointer; color: #ff7979"></b-icon>
            </div>
            <i>15 Jan 2022 às 10:23</i>
          </div>
        </section>
      </div> -->



    </div>



    <!-- MODAL ADD COMENTÁRIO -->
    <div>
      <b-modal ref="modal-comentario" hide-footer title="Comentário" size="mx">
        <div class="row text-left col-lg-12">


          <b-form-group label="Informe o nivel de privacidade" v-if="userPodeAddPrivado">
            <b-form-radio-group
              id="radio-group-1"
              v-model="selPrivacidade"
              :options="optionsPrivacidade"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>

          <b-col sm="12">
            <b-form-group label-cols="12" label-cols-lg="12" label="">
                <div>
                    <b-form-textarea 
                    v-model="textComentario"
                    rows="10"
                    ></b-form-textarea>
                </div>           
            </b-form-group>   
          </b-col>

        </div>

        <b-button class="mt-3" variant="success" block style="height: 40px;" @click="confirmar">Confirmar</b-button>
      </b-modal>
    </div>
    <!-- MODAL ADD COMENTÁRIO FIM -->


    <!-- MODAL CONFIRMAR -->
    <div>
      <b-modal ref="modal-confirmar" hide-footer title="Deseja remover o comentário?">

        <b-container class="bv-example-row">
          <b-row align-h="around">
            <b-button class="mt-3 botao" variant="success" block @click="apagarComentario">Sim</b-button>
            <b-button class="mt-3 botao" variant="danger" block @click="$refs['modal-confirmar'].hide()">Não</b-button>
          </b-row>
        </b-container>

      </b-modal>
    </div>
    <!-- MODAL APAGAR RESERVA FIM -->


  </div>

</template>



<script>
// import api from "../../services/api";
import { acessoRestrito } from "@/global";
export default {

  name: 'Comentarios',
  props: {
    reserva: {},
    modoEdicao: null
  },

  data() {
    return {

      optionsPrivacidade: ['Público','Privado'],
      selPrivacidade: 'Público',

      comentario: {},   
      textComentario: '',
      userName: null,
      userPodeAddPrivado: false,
      userPodeVerPrivadoTerceiros: null,
      userPodeVerApagados: null,
  
    }
  },

  created() {

    // await new Promise(r => setTimeout(r, 1000));
    const authLocal = localStorage.getItem('user')
    const auth = JSON.parse(authLocal)
    this.userName = auth.nome
    this.userPodeAddPrivado = acessoRestrito(296, false)
    this.userPodeVerPrivadoTerceiros = acessoRestrito(298, false)
    this.userPodeVerApagados = acessoRestrito(299, false)

    // console.log('componente', this.reserva.comentarios)
    const comentarios = JSON.parse(localStorage.getItem('comentariosOriginal'))
    this.reserva.comentarios = comentarios
    // console.log('componente2', this.reserva.comentarios)
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    showAdicionar() {
      acessoRestrito(295)
      this.$refs["modal-comentario"].show();
    },


    confirmar() {

      if (this.selPrivacidade == 'Privado') acessoRestrito(296)
      
      if (!this.textComentario) {
        this.msgNotification('Informe seu comentário.', 'warning', 5000)
        return
      }

      const comentario = {
        idReserva: this.reserva.id,
        comentario: this.textComentario,
        privacidade: this.selPrivacidade,
        classCSS: 'row row-1',
        iconCSS: 'clock-fill',
        userCreate: this.userName
      }

      this.reserva.comentarios.push(comentario)
      this.$refs["modal-comentario"].hide();
      this.msgNotification('O comentário será adicionado após confirmar a reserva.', 'warning', 7000)

    },


    showApagarComentario(comentario) {

      const user = this.$store.state.auth.user
      if (comentario.userCreate != user.userCreate) 
      acessoRestrito(297)

      this.comentario = comentario      
      this.$refs["modal-confirmar"].show();
    },

    apagarComentario() {
      this.comentario.userDelete = this.userName
      this.$refs["modal-confirmar"].hide();
    }


  },


  computed: {

    comentariosComputed() {

      // TODA REGRA DE VISUALIZAÇÃO DE RESERVA É FEITA AQUI DENTRO.

      // Regra para verificar permissão de quem pode ver comentários apagados.
      //------------------------------------------------------------------------
      var filtra = this.reserva.comentarios.filter(item => !item.userDelete)
      if (this.userPodeVerApagados)
      filtra = this.reserva.comentarios
      //------------------------------------------------------------------------
        

      // Aqui vem um if para checar permissão, caso não tenha, passa por esse filtro
      if (!this.userPodeVerPrivadoTerceiros) {
        filtra = filtra.filter(item =>  item.privacidade == 'Público' || 
                                      item.privacidade == 'Privado' && item.userCreate == this.userName)
      } 
     

      // const filtra = this.reserva.comentarios
      
      return filtra
    },
  }


};
</script>



<style scoped>

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

/* .button-cadastrar {
  margin-top: 30px;
  height: 40px;
  width: 190px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
} */

.wrapper{
  background: #ff7979;
  max-width: 1080px;
  margin: 20px auto;
  padding:  0 20px;
  position: relative;
}

.wrapper .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.wrapper .row{
  display: flex;
}

.wrapper .row-1{
  justify-content: flex-start;
}

.wrapper .row-2{
  justify-content: flex-end;
}

.wrapper .row section{
  margin-top: 5px;
  margin-bottom: 5px;
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.wrapper .row section::before{
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: 28px;
  /* z-index: -1; */
  transform: rotate(45deg);
}
.row-1 section::before{
  right: -7px;
}
.row-2 section::before{
  left: -7px;
}

.row section .icon{
  position: absolute;
  background: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ff7979;
  top: 13px;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.row-1 section .icon{
  right: -60px;
}
.row-2 section .icon{
  left: -60px;
}

.row section .details,
.row section .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row section .details .title{
  font-size: 22px;
  font-weight: 600;
}

.row section p{
  margin: 10px 0 17px 0;
}

.row section .bottom a{
  text-decoration: none;
  background: #ff7979;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 400;
}


.wrapper .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
}


.botao-confirmar {
  width: 200px; 
  height: 45px; 
  margin-top: 50px;
  margin-left: 11px;
  background: #ff7979;
  color: #fff;
  font-weight: bold;
}

.botao {
  width: 200px; 
  height: 45px; 
  margin-top: 50px;
  margin-left: 11px;
}



@media(max-width: 772px){

  .wrapper .center-line{
    left: 40px;
  }
  .wrapper .row{
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section{
    width: 100%;
  }
  .row-1 section::before{
    left: -7px;
  }
  .row-1 section .icon{
    left: -60px;
  }

}



</style>