import axios from 'axios'

const api = axios.create({
    
    baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:10010/' : process.env.VUE_APP_URL_API 
    // baseURL: process.env.NODE_ENV == 'development' ? 'http://192.168.68.103:10010/' : process.env.VUE_APP_URL_API 
})

// api.defaults.headers.common['Authorization'] = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Vzc28iOiJ3ZWJDbGllbnRlIiwiYWRtaW4iOmZhbHNlLCJpZFVzZXIiOjEsImNvb3BlcmF0aXZhIjozLCJtYXRyaWN1bGEiOjk2MDAsIm5vbWUiOiJNYXJjb3MgUm9iZXJ0byIsImVtYWlsIjoia2VwYXM3QGdtYWlsLmNvbSIsImlhdCI6MTYwNjkyMjM0NywiZXhwIjoxNjA5NTE0MzQ3fQ.8gG0b38xBcfVwhh0aMuDBEBwZNg1EZ7utCFcpKZyQMY'

const success = res => res
const error = err => {
    if (401 === err.response.status) {
        // console.log('Expulsooooo')
        window.location = '/login'
    } else {
        return Promise.reject(err)
    }
}

api.interceptors.response.use(success, error)

export default api 