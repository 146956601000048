<template>

    <div class="wrapper">

      <div class="center-line"></div>

      <div class="row row-1">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="house-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>

      <div class="row row-2">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="star-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simpl500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>

      <div class="row row-1">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="house-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>

      <div class="row row-2">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="star-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simpl500s, wently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>

      <div class="row row-1">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="house-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>

      <div class="row row-2">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="star-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simpl500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>

      <div class="row row-1">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="house-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>

      <div class="row row-2">
        <section>
          <i class="icon">
            <b-icon class="b-icon" icon="star-fill" variant="none" style="height: 18px; color: #ff7979;"></b-icon>
          </i>
          <div class="details">
            <span class="title"> Titulo da sessão</span>
            <span>15 Jan 2022</span>
          </div>
          <p>orem Ipsum is simpl500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          <div class="bottom">
            <a href="#">read more</a>
            <i>- Someone famous</i>
          </div>
        </section>
      </div>




    </div>

</template>



<script>
// import api from "../services/api";
// import { msg } from "@/global";
export default {

  data() {
    return {

      contas: [],   
  
    }
  },

  created() {

    this.getInfoDashboad()
  },

  methods: {





    }

};
</script>



<style scoped>

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.wrapper{
  background: #ff7979;
  max-width: 1080px;
  margin: 50px auto;
  padding:  0 20px;
  position: relative;
}

.wrapper .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.wrapper .row{
  display: flex;
}

.wrapper .row-1{
  justify-content: flex-start;
}

.wrapper .row-2{
  justify-content: flex-end;
}

.wrapper .row section{
  margin-top: 15px;
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.wrapper .row section::before{
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: 28px;
  /* z-index: -1; */
  transform: rotate(45deg);
}
.row-1 section::before{
  right: -7px;
}
.row-2 section::before{
  left: -7px;
}

.row section .icon{
  position: absolute;
  background: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ff7979;
  top: 13px;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.row-1 section .icon{
  right: -60px;
}
.row-2 section .icon{
  left: -60px;
}

.row section .details,
.row section .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row section .details .title{
  font-size: 22px;
  font-weight: 600;
}

.row section p{
  margin: 10px 0 17px 0;
}

.row section .bottom a{
  text-decoration: none;
  background: #ff7979;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 400;
}


.wrapper .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
}


@media(max-width: 772px){

  .wrapper .center-line{
    left: 40px;
  }
  .wrapper .row{
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section{
    width: 100%;
  }
  .row-1 section::before{
    left: -7px;
  }
  .row-1 section .icon{
    left: -60px;
  }

}



</style>