import api from "@/services/api";
import dayjs from 'dayjs'
import { acessoRestrito } from "@/global"

export default {
  name: "Reservas",
  props: {
    origem: null,
    dataIn: null,
    dataOut: null,
    qtdHospedes: null,
  },

  data() {
    return {

      load: false,
      isActiveCss: true, 
      
      listaFiltrosStatus: ['Disponível','Ocupado','Manutenção'],
      filtrosStatus: ['Disponível','Ocupado','Manutenção'],
      
      listaFiltrosVisao: ['Cidade','Mar','Vegetação','Cadeirante'],
      filtrosVisao: ['Cidade','Mar','Vegetação','Cadeirante'],

      listaFiltrosAndar: ['Térreo','1º Andar','2º Andar','3º Andar'],
      filtrosAndar: ['Térreo','1º Andar','2º Andar','3º Andar'],

      listaFiltrosPredio: ['Novo','Velho'],
      filtrosPredio: ['Novo','Velho'],

      quantDisponivel: 0,
      quantOcupado: 0,
      quantManutencao: 0,
      quantTotal: 0,
      quantAcomodacoes: 0,

      colonia: null,
      quarto: {},

      quartos: [],
      fieldsQuartos: [
        {key: 'id', label: 'Número'},
        {key: 'visao', label: 'Visão'},
        {key: 'andar', label: 'Andar'},
        {key: 'predio', label: 'Prédio'},
        {key: 'solteiro', label: 'Solteiro', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'beliche', label: 'Beliche', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'casal', label: 'Casal', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'totalAcomodacoes', label: 'Total acomod.', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'status', label: 'Status'},
        {key: 'reservas', label: ''},
        // "reservas",
        "Selecione"
      ],

      fieldsQuartosEditar: [
        {key: 'id', label: 'Número'},
        {key: 'visao', label: 'Visão'},
        {key: 'andar', label: 'Andar'},
        {key: 'predio', label: 'Prédio'},
        {key: 'solteiro', label: 'Solteiro', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'beliche', label: 'Beliche', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'casal', label: 'Casal', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'totalAcomodacoes', label: 'Total acomod.', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'status', label: 'Status'},
        {key: 'reservas', label: ''},
        // "reservas",
        {key: 'Editar', label: ''},
        {key: 'Manutencao', label: ''},
        {key: 'Historico', label: ''}
      ],

      fieldsReservas: [
        // { key: "Tipo", label: "", sortable: true },
        { key: "cod", label: "Nº Reserva", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "dataIn", label: "Entrada", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "qtdHospedes", label: "Hspedes", sortable: true },
      ],

      historico: [],
      fieldsStatusHistorico: [
        {key: 'status', label: 'Status'},
        {key: 'manutencaoAte', label: 'Manutenção Até'},
        {key: 'motivo', label: 'Motivo'},
        {key: 'userCreate', label: 'Usuário'},
        {key: 'dataCreate', label: 'Data' },
      ],


      motivos: [
        'AZULEIJO',
        'CHUVEIRO',
        'ELÉTRICA',
        'ENCANAMENTO',
        'JANELA',
        'LÂMPADA',
        'MÓVEIS',
        'PIA',
        'PINTURA',
        'PISO',
        'PORTA',
        'RESISTÊNCIA',
        'TORNEIRA',
        'VASO SANITÁRIO',
        'VITRO'
      ]



    }
  },


  created() {

    this.isActiveCss = this.origem == 'Reservas' ? false : true
    
    this.colonia = this.$store.state.colonia 
    // console.log(this.origem)
    this.getQuartos()
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },


    getQuartos() {

      
      this.load = true

      const url = !this.origem
                  ? `reservas/quartos/0/1900-01-01/1900-01-01/${this.colonia}`
                  : `reservas/quartos/${this.qtdHospedes}/${this.dataIn}/${this.dataOut}/${this.colonia}`
      
      api.get(url)
      .then(res => {
        this.quartos = res.data
        this.load = false

      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },


    selecionarQuarto(quarto) {
      // console.log(quarto,'quartoo')

      if (quarto.status == 'Ocupado') {
        this.msgNotification('O quarto estará ocupado neste período.', 'warning', 5000)
        return
      }

      if (quarto._rowVariant == 'warning') {
        this.msgNotification('O quarto não possui acomodações suficiente para está reserva.', 'warning', 5000)
        return
      }



      this.msgNotification('Quarto atribuído a reserva.', 'info', 5000)

      const retorno = {
        quarto: quarto.id,
        qtdAcomodacoes: quarto.totalAcomodacoes
      }

      this.$emit('EMITselecionaQuarto', retorno)
    },

    editar(quarto) {
      acessoRestrito(3)

      this.quarto = quarto
      this.$refs["modal-editar"].show();
    },
    
    
    confirmarAlteracao() {
      // console.log(this.quarto)
      
      this.$refs["modal-editar"].hide();

      this.quarto.colonia = this.colonia
      api.put(`reservas/quartos`, this.quarto)
      .then(() => {
        this.msgNotification('Quarto alterado.', 'success', 5000)
        this.getQuartos()
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'warning', 9000)
      });
      
    },
    
    
    modalStatusHistorico(quarto) {

      if (quarto.historico.length == 0) {
        this.msgNotification('O quarto não possui histórico.', 'info', 3000)
        return
      }
      
      this.historico = quarto.historico
      this.$refs["modal-status-historico"].show()
    },



    async mudarStatus(quarto) {
      // console.log(quarto)
      acessoRestrito(4)

      this.quarto = quarto
      

      if (quarto.status == 'Manutenção') {

        this.quarto.manutencaoAte = null,
        this.quarto.manutencaoMotivo = null,

        this.quarto._rowVariant = 'none'
        this.quarto.status = 'Disponível'
        this.updateStatus('Status disponível.')

      } 
      else 
      if (quarto.status == 'Disponível') {
        this.$refs["modal-manutencao"].show();
      }
      else 
      if (quarto.status == 'Ocupado') {

        this.msgNotification(`Este quarto possui reservas no futuro.`, 'info', 3000)
        await new Promise(r => setTimeout(r, 3000));
        this.msgNotification(`Certifique-se que o fim da manutenção acontecerá antes da próxima reserva.`, 'info', 6000)
        await new Promise(r => setTimeout(r, 6000));
        this.$refs["modal-manutencao"].show();
      }

    },

    mudarParaManutencao() {

      const corOld = this.quarto._rowVariant
      const statusOld = this.quarto.status

      // this.$refs["modal-manutencao"].hide();
      this.quarto._rowVariant = 'danger'
      this.quarto.status = 'Manutenção'
      this.updateStatus('Status Manutenção.', corOld, statusOld)
    },


    updateStatus(msg, corOld, statusOld) {

      this.quarto.colonia = this.colonia
      // console.log('corOld, statusOld', corOld, statusOld)

      
      api.put(`reservas/quarto/mudar-status`, this.quarto)
      .then(() => {
          this.msgNotification(msg, 'success', 5000)
          this.$refs["modal-manutencao"].hide();
      })
      .catch((err) => {
          this.msgNotification(err.response.data, 'warning', 9000)

          if (statusOld) {
            this.quarto._rowVariant = corOld ? corOld : 'none'
            this.quarto.status = statusOld 
          }
      });  

    }

  },

  computed: {

    filtrarQuartos() {



      this.quantDisponivel = 0
      this.quantOcupado = 0
      this.quantManutencao = 0
      this.quantAcomodacoes = 0
        
            
      // Filtra
      //---------------------------------------------------------------------
      let listaFiltrada = this.quartos.filter((quarto) => {
        
        const filtroMatch = this.filtrosAndar.filter(filtro => filtro == quarto.andar)
        if (filtroMatch.length > 0) return quarto 
      })
      .filter(quarto => {
        const filtroMatch = this.filtrosStatus.filter(filtro => filtro == quarto.status)
        if (filtroMatch.length > 0) return quarto 
      })
      .filter(quarto => {
        const filtroMatch = this.filtrosPredio.filter(filtro => filtro == quarto.predio)
        if (filtroMatch.length > 0) return quarto 
      })
      .filter(quarto => {
        const filtroMatch = this.filtrosVisao.filter(filtro => filtro.toUpperCase() == quarto.visao)
        if (filtroMatch.length > 0) return quarto 
      })
      //---------------------------------------------------------------------


      // Sumario
      //---------------------------------------------------------------------
      listaFiltrada.forEach(item => {
        if (item.status == 'Disponível') this.quantDisponivel += 1
        if (item.status == 'Ocupado') this.quantOcupado += 1
        if (item.status == 'Manutenção') this.quantManutencao += 1

        this.quantAcomodacoes = this.quantAcomodacoes + item.totalAcomodacoes
      })
      this.quantTotal = Number(this.quantDisponivel) + Number(this.quantOcupado) + Number(this.quantManutencao)
      //---------------------------------------------------------------------


      return listaFiltrada
    },
  }


}