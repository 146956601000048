import api from '../services/api'

export default {

    namespaced: true,
    state: {
        token: null,
        user: null,
        isMenuVisible: false,
        loading: false
    },

    mutations: {
        toggleMenu(state, isVisible){
            if(!state.user) {
                state.isMenuVisible = false
                return
            }
            state.isMenuVisible = isVisible
        },
        setUser(state, user){
            //console.log(acao)
            state.user = user
            if(user){
                api.defaults.headers.common['Authorization'] = `bearer ${user.token}`
                state.isMenuVisible = true
            } else {
                delete api.defaults.headers.common['Authorization']
                state.isMenuVisible = false
            }

        },
        setLoading(state, valor){
            state.loading = valor
        }
    },

}