import Vue from 'vue'
// import Toasted from 'vue-toasted'
import store from '@/stores/global'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);


// export function showError(e) {
//     if (e && e.response && e.response.data) {
//         Vue.toasted.global.defaultError({ msg: e.response.data })
//     } else 
//     if (typeof e === 'string') {
//         Vue.toasted.global.defaultError({ msg: e })
//     } else {
//         Vue.toasted.global.defaultError({ msg: e })
//     }
// }

// export function msg(texto, cor, tempo) {

//     var tipo = ''
//     if (cor == 'verde') {
//         cor = 'check' 
//         tipo = 'success' 
//     }
//     if (cor == 'amarelo') { 
//         cor = 'warning' 
//         tipo = 'info' 
//     }
//     if (cor == 'vermelho') {
//         cor = 'error' 
//         tipo = 'error'
//     }

//     Vue.use(Toasted, {
//         iconPack : 'material',
//         theme: "toasted-primary", 
//         position: "top-right", 
//         fullWidth: false
//     })

//     Vue.toasted.show(texto, {
//         icon: cor,
//         duration : tempo,
//         type: tipo
//     });

// }


export function msgNotification(texto, tipo, tempo) {
    Vue.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
    })
}


export function acessoRestrito(acesso, pararFluxo = true) {

    const user = store.state.auth.user
	const existe = user.permissoes.filter(item => item == acesso)
    // console.log(existe)

    if (existe.length == 0 && pararFluxo) {
        msgNotification('VOCÊ NÃO POSSUI PERMISSÃO.', 'info', 5000)
        throw 'fim'
    }
    else {
        return existe.length == 0 ? false : true
    }
}

// export default { showError, msg, acessoRestrito }
export default { acessoRestrito }