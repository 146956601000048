<template>
  <div id="page">

    <b-list-group>
      <div>
        <h2>Consultar Sócio</h2>

          <div class="form-group col-lg-4">
            <b-form-group label="Buscar o sócio" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
              <b-input-group>
                <input 
                class="form-control"  
                v-model="filtro" 
                v-mask="'###.###.###-##'"
                placeholder="000.000.000-00"
                @keyup="keyupBuscaSocio($event)"
                />
                <b-input-group-append is-text class="text-monospace cursor" @click="getSocio">
                  <b-icon icon="search" variant="success" class="cursor"></b-icon>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
      </div>



      <b-card no-body>
        <b-tabs card  pills start>

          <b-tab no-body title="Situação sócio">
            
            

            <!-- <h5>Situação sócio</h5> -->
            <div class="divider"></div>


            <div class="row text-left col-lg-12">

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Nome</label>
                <b-form-input
                  id="input-1"
                  class="text-uppercase"
                  v-model="situacaoSocio.nome"
                  readonly
                ></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Situação cadastral</label>
                <b-form-input
                  id="input-1"
                  class="text-uppercase"
                  v-model="situacaoSocio.situacao"
                  readonly
                ></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Situação financeira</label>
                <b-form-input
                  id="input-1"
                  class="text-uppercase"
                  v-model="situacaoSocio.situacaoFinanceira"
                  readonly
                ></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Data filiação</label>
                <b-form-input
                  id="input-1"
                  class="text-uppercase"
                  v-model="situacaoSocio.dataFiliacao"
                  readonly
                ></b-form-input>
              </div>

            </div>

            <div class="tabela">
              <b-table
                id="my-table"
                striped
                hover
                class="text-left"
                ref="selectableTable"
                :items="situacaoSocio.movimentos"
                :fields="fieldsMovimentos"
                :busy="load"
                small
                outlined
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong></strong>
                  </div>
                </template>

              </b-table>
            </div>


          </b-tab>



          <b-tab no-body title="Gratuidade">


            <!-- <h5>Gratuidade</h5> -->
            <div class="divider"></div>



            <div class="row text-left col-lg-12">

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Dias disponíveis</label>
                <b-form-input
                  id="input-1"
                  class="text-uppercase"
                  v-model="gratuidade.diasDisponiveis"
                  readonly
                ></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Dias utilizados</label>
                <b-form-input
                  id="input-1"
                  class="text-uppercase"
                  v-model="gratuidade.diasUtilizados"
                  readonly
                ></b-form-input>
              </div>

            </div>

            <div class="tabela">
              <b-table
                id="my-table"
                striped
                hover
                class="text-left"
                ref="selectableTable"
                :items="gratuidade.reservas"
                :fields="fieldsReservasGratuidade"
                :busy="load"
                small
                outlined
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong></strong>
                  </div>
                </template>

              </b-table>
            </div>


          </b-tab>






          <b-tab no-body title="Créditos">


            <!-- <h5>Gratuidade</h5> -->
            <div class="divider"></div>


            <div class="row text-left col-lg-12">

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Crédito disponível</label>
                <b-form-input
                  id="input-3"
                  class="text-uppercase input-creditorestaurado"
                  v-model="credito.valor"
                  readonly
                ></b-form-input>
              </div>

              <div class="form-group col-lg-3" v-if="opcoesCreditosShow">
                <b-button class="botao-restaurar" variant="success" block @click="restauraCredito">Restaurar crédito</b-button>
              </div>

              <div class="form-group col-lg-3" v-if="opcoesCreditosShow">
                <b-button class="botao-restaurar" variant="danger" block @click="expiraCredito">Expirar crédito</b-button>
              </div>


              <div class="form-group col-lg-3" v-if="opcoesCreditosShow">
                <label><span class="text-danger"></span> Crédito expirado</label>
                <b-form-input
                  id="input-2"
                  class="text-uppercase input-creditoexpirado"
                  v-model="credito.valorExpirado"
                  readonly
                ></b-form-input>
              </div>

            </div>

            <div class="tabela">
              <b-table
                id="my-table"
                striped
                hover
                class="text-left"
                ref="selectableTable"
                :items="credito.reservas"
                :fields="fieldsReservasCreditos"
                :busy="load"
                small
                outlined
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong></strong>
                  </div>
                </template>

              </b-table>
            </div>


          </b-tab>





          <b-tab no-body title="Reservas">

            <!-- <h5>Reservas</h5> -->
            <div class="divider"></div>

            <div class="tabela">
              <b-table
                id="my-table"
                striped
                hover
                class="text-left"
                ref="selectableTable"
                :items="reservas"
                :fields="fieldsReservas"
                :busy="load"
                small
                outlined
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong></strong>
                  </div>
                </template>

              </b-table>
            </div>
          </b-tab>




          <b-tab no-body title="Veículos">

            <!-- <h5>Veículos</h5> -->
            <div class="divider"></div>

            <div class="tabela">
              <b-table
                id="my-table"
                striped
                hover
                class="text-left"
                ref="selectableTable"
                :items="veiculos"
                :fields="fieldsVeiculos"
                :busy="load"
                small
                outlined
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong></strong>
                  </div>
                </template>

              </b-table>
            </div>

          </b-tab>




          <b-tab no-body title="Comentários">

            <div class="tabela">
              <!-- <h5>Comentários</h5> -->
              <!-- <div class="divider"></div> -->
              <Comentarios :comentarios="this.comentarios"></Comentarios> 
            </div>

          </b-tab>
            

        </b-tabs>
      </b-card>


    </b-list-group>




  </div>
</template>

<script>
// import dayjs from "dayjs"
// import axios from "axios";
import api from "../services/api";
import Comentarios from "./Comentarios"
import { acessoRestrito } from "@/global";

export default {
  name: "ConsultarSocio",
  components: { 
    Comentarios
  },

  data() {
    return {

      filtro: '',



      situacaoSocio: {
        situacao: null, 
        situacaoFinanceira: null, 
        dataFiliacao: null, 
        movimentos: []
      },

      fieldsMovimentos: [
        {key: 'vencimento', label: 'Vencimento'},
        {key: 'dataPagamento', label: 'Data recebida'},
        {key: 'valor', label: 'Valor'},
        {key: 'valorPago', label: 'Valor recebido'},
      ],
   

      gratuidade: {
        diasDisponiveis: 0,
        diasUtilizados: 0,
        reservas: []
      },

      fieldsReservasGratuidade: [
        {key: 'cod', label: 'Reserva'},
        {key: 'colonia', label: 'Colônia'},
        {key: 'dataIn', label: 'Entrada'},
        {key: 'dataOut', label: 'Saida'},
        {key: 'quantDiasGratuidade', label: 'Qtd dias'}
      ],



      credito: {
        valor: 0,
        reservas: []
      },

      fieldsReservasCreditos: [
        {key: 'cod', label: 'Reserva'},
        {key: 'colonia', label: 'Colônia'},
        {key: 'dataIn', label: 'Entrada'},
        {key: 'dataOut', label: 'Saida'},
        {key: 'status', label: 'Status'},
        {key: 'credito', label: 'Crédito'},
        {key: 'valorCredito', label: 'Valor Crédito'}
      ],


      reservas: {
        diasDisponiveis: 0,
        diasUtilizados: 0,
        reservas: []
      },

      fieldsReservas: [
        {key: 'cod', label: 'Reserva'},
        {key: 'colonia', label: 'Colônia'},
        {key: 'dataIn', label: 'Entrada'},
        {key: 'dataOut', label: 'Saida'},
        {key: 'quartos', label: 'Quarto'},
        {key: 'valorTotal', label: 'Valor total'}
      ],


      veiculos: {
        diasDisponiveis: 0,
        diasUtilizados: 0,
        reservas: []
      },

      fieldsVeiculos: [
        {key: 'modelo', label: 'Modelo'},
        {key: 'placa', label: 'Placa'}
      ],

      comentarios: [],

      items: [], 
      load: false,
      opcoesCreditosShow: false

    };
  },

  created() {  

    this.opcoesCreditosShow = acessoRestrito(501, false)

  },


  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    keyupBuscaSocio(evento) {

      // console.log(this.filtro.length)
      // console.log(evento)
      if (evento.keyCode == 13 || this.filtro.length == 14) 
      this.getSocio()
    },

    getSocio() {

      if (!this.filtro) {
        this.msgNotification('Informe o CPF.', 'warning', 5000)
        return
      }

      this.load = true

      api.get(`consulta-socio/${this.filtro}`)
      .then((res) => {
        this.load = false
        // console.log(res.data)
        this.situacaoSocio = res.data.situacaoSocio
        this.gratuidade = res.data.gratuidade
        this.credito = res.data.credito
        this.reservas = res.data.reservas
        this.veiculos = res.data.veiculos
        this.comentarios = res.data.comentarios
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'warning', 5000)
      });

    },   


    restauraCredito() {

      api.put(`consulta-socio/restaura-credito`, { cpf: this.filtro })
      .then(() => {
        this.msgNotification('Crédito restaurado.', 'success', 5000)
        this.getSocio()
      })
      .catch(() => {
        this.msgNotification('Crédito não encontrado.', 'warning', 5000)
      });
    },   

    expiraCredito() {

      api.put(`consulta-socio/expira-credito`, { cpf: this.filtro })
      .then(() => {
        this.msgNotification('Crédito expirado.', 'success', 5000)
        this.getSocio()
      })
      .catch(() => {
        this.msgNotification('Crédito não encontrado.', 'warning', 5000)
      });
    },


     resetForm() {

      const form = this.form
      
      // this.filtroNomeSocio = null
      // this.socioSituacaoNoCadastro = null
      
      for (var proper in form){
        
        form[proper] = null
      }
      
    },



  }

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */



#page h2 {
  margin-top: 60px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

h5 {
  margin-top: 65px;
  margin-bottom: 5px;
  color: #8f23d6;
}

.divider {
  /* margin-top: 25px; */
  margin-bottom: 35px;
  height: 2px;
  width: 100%;
  background-color: #8f23d6;
}


.tabela {
  width: 80vw;
  margin-top: 20px;
}

img, .cursor {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

#input-1{
  background-color: #fff;
}

.botao-restaurar {
  margin-top: 32px;
}

.input-creditoexpirado {
  background-color: rgb(252, 183, 183);
}

.input-creditorestaurado {
  background-color: aquamarine
}


@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

}

</style>