<template>
  <div>
  


    <div class="row text-left col-lg-12">

      <div class="form-group col-lg-4">
        <label><span class="text-danger"></span> Valor total de crédito</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="valorTotalView"
          placeholder="0,00"
          readonly
        ></b-form-input>
      </div>

      <div class="form-group col-lg-4">
        <label><span class="text-danger"></span> Informe o valor que deseja utilizar</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="valorAplicado"
          placeholder="0,00"
          @keyup="formatMoeda($event)"
        ></b-form-input>
      </div>

      <div class="form-group col-lg-4">
        <b-button class="button-aplicar" variant="success" block @click="aplicarCredito()">Aplicar crédito</b-button>
      </div>

      


      <div class="tabela">
      <b-table
        id="table-creditos" 
        striped
        hover 
        ref="selectableTable"
        class="text-left" 
        :items="reservas" 
        :fields="fieldsReservas" 
        :busy="load"
        value="true"
        outlined
        small
        >              

        <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong></strong>
            </div>
        </template>


      </b-table>
    </div>


  </div>

    


  </div>
</template>

<script>
import api from "@/services/api";
import dayjs from 'dayjs'

export default {
  name: "Creditos",
  props: {
    idReserva: null,
    cpf: null
  },

  data() {
    return {

      load: false,
      valorAplicado: 0,
      valorTotal: 0,
      valorTotalView: 0,

      reservas: [],
      fieldsReservas: [
        // { key: "Tipo", label: "", sortable: true },
        { key: "cod", label: "Nº Reserva", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "dataIn", label: "Entrada", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "dataOut", label: "Saida", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "valorSinal", label: "Créditos", formatter: value => { 
          return  value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL'
          });
        }}
      ],



    }
  },


  created() {

    // const colonia = this.$store.state.colonia 

    console.log('this.cpf', this.cpf)
    this.getReservas()
  },
  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    getReservas() {

      this.load = true
      
      api.get(`reservas-creditos/${this.cpf}`)
      .then(res => {
        this.reservas = res.data.reservas
        this.valorTotal = res.data.valorCredito
        this.load = false

        this.valorTotalView = this.valorTotal.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
        });
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },


    formatMoeda(event) {
      
      // console.log(event.target.value)
      let input = event.target

      var valor = input.value.replace(/,/, '')

      if (valor.length > 2)
          input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
      else   
      if (valor.length > 0 && valor.length < 4)
          input.value = valor
      else   
          input.value = ''

    },


    aplicarCredito() {


      const dados = {
        idReservaQueReceberaOCredito: this.idReserva,
        cpf: this.cpf,
        valorCredito: this.valorAplicado,
        valorTotal: this.valorTotal,
      }
      
      api.put(`reservas-creditos`, dados)
      .then(() => {
        
        this.msgNotification('O valor do crédito foi aplicado.', 'success', 5000) 
        this.$emit('EMITaplicarCredito')
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'warning', 5000)
      });
    },


  }



}

</script>

<style scoped>


.tabela {
  width: 80vw;
  margin-top: 30px;
  /* margin-right: -30px; */
}

.table-hospedes-sub {
  width: 100%;
  /* margin-top: 30px; */
  
}

.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>