<template>
  <div>


    <div class="text-center">
      <b-spinner variant="warning" label="Spinning"></b-spinner>
      <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="danger" label="Spinning"></b-spinner>
      <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="info" label="Spinning"></b-spinner>
      <b-spinner variant="info" type="grow" label="Spinning"></b-spinner>
      <!-- <b-spinner variant="primary" label="Spinning"></b-spinner>
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
      <b-spinner variant="success" label="Spinning"></b-spinner>
      <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
    </div>
  

    <div class="tabelaProdutos" v-show="false">
        <b-table id="tabelaProdutos" striped hover :items="dados.produtos" :fields="fields"></b-table>
    </div>



  </div>
</template>

<script>
import { acessoRestrito } from "@/global";
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";
import dayjs from 'dayjs';

export default {
  name: "GerarPdf",
  props: {
    dados: {}
  },
  

  data() {
    return {

      load: false,
      dataHoje: null,
      dataIni: null,
      dataFim: null,
      valorTotal: null,
      usuario: {},

      fields: [
        { key: "produto", label: "Produto" },
        { key: "quantidade", label: "Quantidade" },
        { key: 'valor', label: 'Valor unidade', formatter: value => {
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }, thClass: 'text-right', tdClass: 'text-right'},
        { key: 'valorTotal', label: 'Valor total', formatter: value => {
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }, thClass: 'text-right', tdClass: 'text-right'}
      ],


    }
  },


  async created() {

    this.usuario = JSON.parse(localStorage.getItem('user'))

    this.dataIni = dayjs(this.dados.dataFiltro[0]).format('DD/MM/YYYY')
    this.dataFim = dayjs(this.dados.dataFiltro[1]).format('DD/MM/YYYY')

    this.dataHoje = dayjs().format('DD/MM/YYYY')
    
    this.valorTotal = this.dados.produtos.reduce((total, item) => total + Number(item.valorTotal), 0)
    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })


    await new Promise(r => setTimeout(r, 2000))
    this.gerarPdf()
  },

  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },
    

    getImgUrl(nomeFile) {
        var images = require.context('../../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },


    async gerarPdf() {

      acessoRestrito(250)


      const colonia = this.$store.state.colonia
      const coloniaTexto = colonia == 'Chico Amaro'
      ? 'Colonia de Férias Chico Amaro - Itanhaém - SP'
      : 'Colonia de Férias Guarino Fernances - Presidênte Epitácio - SP'





      
      var doc = jsPDF('p', 'mm', [210, 297]);
      // var doc = jsPDF('l', 'mm', [210, 297]);

  

      // Topo
      //------------------------------------------------------------------
      var img = new Image()
      img.src = this.getImgUrl('logoSind') 
      doc.addImage(img, 'png', 9, 8, 27, 27) 

      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()

      doc.setFontSize(17).setFont(undefined, 'bold'); 
      doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
      doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text(coloniaTexto, width/2, 28, { align: 'center' })
      
      // doc.setFontSize(8).setFont(undefined, 'bold'); 
      // doc.text('Rua Erasmo Braga, 307, 3º andar, - Presidente Altino - Osasco - SP - CEP:06213-000 - Telefone: (11) 3682-9303', width/2, 34, { align: 'center' })

      // doc.setDrawColor(0, 0, 0);
      // doc.line(0, 36, 500, 36);


      doc.setFontSize(16);          
      doc.text('Relatório - Bar', width/2, 46, { align: 'center' })

      // doc.setFontSize(11).setFont(undefined, 'bold'); 
      // doc.text(coloniaTexto, width/2, 52, { align: 'center' })
      //------------------------------------------------------------------



      doc.autoTable({
        body: [

          [
            {
              content: `PERÍODO: ${this.dataIni} até ${this.dataFim}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `VALOR TOTAL: ${this.valorTotal}`,
              styles: { halign: "right", fillColor: [244, 247, 249] }
            }            
          ],


        ],
        styles: {
          lineColor: [73, 138, 159],
          lineWidth: 0.2
        },
        theme: "plain",
        startY: 55
      });
                              



      // Tabela PRODUTOS
      //------------------------------------------------------------------
      doc.autoTable({
          html: '#tabelaProdutos',
          tableHegth: height + 20,
          margin: {top: 51, left: 14}, 
          // styles: { fillColor: "#43a047" },
          // headStyles: {fillColor : [124, 95, 240]}, 
          // alternateRowStyles: {fillColor : [231, 215, 252]}, 
          tableLineColor: [124, 95, 240], tableLineWidth: 0.1,


          didDrawPage: function (data){
              data.settings.margin.top = 9;
              // data.settings.margin.left = 9;
          },
          theme: 'grid',
          columnStyles: {
              0: {cellWidth: 97},
              1: {cellWidth: 25},                   
              2: {cellWidth: 30},                   
              3: {cellWidth: 30}                  
          }
      }); 

      // var finalY = doc.lastAutoTable.finalY;
      // doc.setFontSize(10);
      // doc.text((width - 50), (finalY + 5), this.itemsConsolidacoes[1].valor);
      //------------------------------------------------------------------




      // Rodapé
      //------------------------------------------------------------------
      doc.setFontSize(9);          
      doc.text(9, (height - 10), 'Usuário: '+this.usuario.nome);

      doc.setFontSize(9);
      doc.text(120, (height - 10), 'Data: ' + dayjs().format('DD/MM/YYYY'));

      doc.setFontSize(9);
      doc.text(150, (height - 10), 'Hora: ' + dayjs().format('HH:mm'));

      var pageCount = doc.internal.getNumberOfPages();
      for(var i = 0; i < pageCount; i++) { 
          doc.setPage(i); 
          let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
          doc.setFontSize(9);
          doc.text('Pág ' + pageCurrent + '/' + pageCount, 190, (height - 10));
      }
      //------------------------------------------------------------------


      let nomePdf = `Relatório Bar • ${dayjs(this.dados.dataFiltro[0]).format('DD-MM-YYYY')} • `
      nomePdf += `${dayjs(this.dados.dataFiltro[1]).format('DD-MM-YYYY')}.pdf`

      doc.save(nomePdf)
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}

.button-imprimir {
  margin-bottom: 25px;
  margin-right: 20px;
  height: 40px;
  width: 210px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>